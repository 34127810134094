import { HighContrastModeDetector } from '@angular/cdk/a11y';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ApplicationConfig,
  Injectable,
  PLATFORM_ID,
  effect,
  inject,
} from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';

type LogoState = { invert: boolean };

const logoState: LogoState = {
  invert: false,
};

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  readonly #state = signalState(logoState);

  readonly invertLogo = this.#state.invert;

  public setLogoInvert(state: boolean) {
    patchState(this.#state, { invert: state });
  }
}

export function provideLogoService(): ApplicationConfig['providers'] {
  return [LogoService];
}
